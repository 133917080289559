/**
 * This composable has reusable logic that allows us to perform the logout and redirect the user
 * appropiately, and close the menu in case it is needed.
 * @param {any} authStore A store, either auth
 * @param {CallableFunction} beforeLogout A function to run just before logging out the user.
 * @returns 
 */
export const useLogoutHandler = (authStore: any, beforeLogout?: CallableFunction) => {
    /** Ref to check if the menu is open */
    const isMenuOpen = ref<boolean>(false);

    /**
     * This method updates the ref of the isMenuOpen so it is closed.
     */
    const closeMenu = (): void => {
        isMenuOpen.value = false;
    }

    /**
     * THis method performs the logout. If there is a provided beforeLogout function it is called before
     * actually performing the logout with the store.
     * 
     * Do keep in mind this calls the store function, which performs a redirect.
     */
    const performLogout = (): void => {
        closeMenu();
        if (beforeLogout) {
            beforeLogout();
        }
        if (authStore.logout && typeof authStore.logout == 'function') {
            authStore.logout();
        }
    }

    return {
        performLogout,
        closeMenu,
        isMenuOpen,
    }

}